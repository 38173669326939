.about-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 0px;
  background-color: $darkblue;
  background-color: #ffff;
  color: #ffff;
  color: $darkblue;
  box-shadow: 10px 10px 20px 5px #ffff, -10px -10px 20px 5px #ffff;
  border-top: 5px solid $orange;
  border-bottom: 5px solid $orange;
  h2{
    margin-bottom: 20px;
    text-align: center;
  }
}

.about-body {
  @include flex(column, center, center);
  img {
    max-width: 300px;
    border-radius: 50%;
    border: 3px solid $darkblue;
  }
  .about-text {
    @include flex(column, center, flex-start);
    p:first-of-type {
      font-size: 1.6rem;
      text-align: left;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 10px;
      line-height: 1.8rem;
    }
  }
}

.about button {
  border: 2px solid $darkblue;
  background-color: #ffff;
  border-radius: 5px;
  padding: 5px 15px;
  margin-left: 5px;
  margin-right: 5px;
  width: calc(200px + 3vw);
  a {
    text-decoration: none;
    color: $orange;
    font-weight: bold;
  }
}
.about button:hover {
  background-color: $darkblue;
  color: #ffff;
  border: 2px solid $darkblue;
  a {
    color: #ffff;
  }
}

@media (max-width: 768px) {
  .about-body img {
    width: 250px;
  }
}
