* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

  h1, h2, .navbar span, .skills-wrapper h3 {
    font-family: 'Poiret One', cursive;
  }

.site-wrapper{
  display: flex;
  flex-direction: column;
  background-image: url('../images/background/background.jpg');
  background-position: center;
  background-attachment: fixed;
}

p, h4, h3, h2, h1 {
  margin-bottom: 0px;
}





