.contact-wrapper {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(8, 14, 32, 0.98);
  color:#ffff;
  text-align: center;
  .contact {
    @include flex(column, center, center);
  a {
    text-decoration: none;
  }
  i {
    margin: 10px;
    padding: 10px;
    font-size: 50px;
    color:#ffff;
    border-radius: 10px;
    transition: ease-in-out 0.2s;
  }
  i:hover {
    font-size: 60px;
  }
}
}

