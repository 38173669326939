@import '../variables.scss';

.navbar {
  background-color: $orange;
  border-bottom: 5px solid $bgcolor;
  color: #ffff;
  padding: 20px 0px;
  overflow-y: visible;
  position: sticky ;
  top: 0;
  z-index: 1;
  font-size: 1.2rem;
  .container {
    justify-content: flex-end;
    text-align: right;
  }
  .navbar-toggler {
    border-color: #ffff;
  }
  .nav-link{
    text-decoration: none;
    font-weight: 300;
    &:hover{
      transition: 0.2s ease-in-out;
      text-decoration: underline;
    }
  }
}

.underline {
  display: inline-block;
}

.underline::after {
  content: '';
  width: 0%;
  height: 1px;
  display: block;
  background: #ffff;
  transition: 300ms;
}

.underline:hover::after {
  width: 100%;
}