.skills-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 0px;
  border-top: 5px solid $orange;
  border-bottom: 5px solid $orange;
  background-color: #ffff;
  box-shadow: 10px 10px 20px 5px #ffff, -10px -10px 20px 5px #ffff;
}

.skills-container {
  h2, h3 {
    text-align: center;
    color: $darkblue;
  }
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 15px;
  }

}

.dev-skills-container, .other-skills-container {
  @include flex(row, center, center);
  flex-wrap: wrap;
}

.skill {
  @include flex(column, center, center);
  // border: 2px solid $darkblue;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffff;
  padding: 5px;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 100px;
  height: 100px;
  img {
    width: 40px;
    margin-bottom: 5px;
  }
  p {
    text-align: center;
    color: $darkblue;
  }
}


.other-skills-container {
  .skill {
    width: 100px;
    height: 120px;
  }
}

// Animation
.skills-container, .other-skills-container {
  i, img {
    transition: ease-in-out 0.2s;
  }
  i:hover {
    font-size: 60px;
  }
  img:hover {
    width: 50px;
  }
}
