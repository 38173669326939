.experience-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px 0px;
    background-color: rgba(8, 14, 32, 1);
    border-top: 5px solid $orange;
    border-bottom: 5px solid $orange;
    box-shadow: 10px 10px 20px 5px #ffff, -10px -10px 20px 5px #ffff;
    color: #ffff;
    h2 {
      text-align:center;
      margin-bottom: 20px;
    }

  .experience-container {
    @include flex (row, center, center);
    flex-wrap: wrap;
  }

  .experience {
    color: $darkblue;
    margin: 20px;
    text-align: center;
  }
}

.flip-card {
  background-color: transparent;
  width: 350px;
  height: 300px;
  // border: 1px solid $darkblue;
  perspective: 1000px; /* Remove for no 3D effect */
}

/* Container to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border: 3px solid $darkblue;
  border-radius: 10px;

}

/* Horizontal flip when hover over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; 
  backface-visibility: hidden;
  background-color: #ffff;

}

.flip-card-front {
  color: $darkblue;
  img {
    max-width: 250px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-bottom: 15px;
    border-bottom: 2px solid grey;
  }
  h3, h4, p {
    margin-top: 5px;
  }
  p {
    font-style: italic;
    font-size: 1.3rem;
  }
}

.flip-card-back {
  background-color: #ffff;
  color: $darkblue;
  transform: rotateY(180deg);
  text-align: left;
  padding: 20px;
  @include flex(column, space-evenly, center);
}