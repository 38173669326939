.title-wrapper {
  min-height: calc(100vh - 70px);
  margin: 0 auto;
  @include flex(column, center, center);
  .title-container {
    @include flex(column, center, center);
  }
  .title {
    text-align: center;
    @include flex(column, center, center);
    background-color: #ffff;
    border: 7px solid $orange;
    color: $darkblue;
    h1 {
      font-size: calc(30px + 3vw);
    }
    h2 {
      font-size: calc(15px + 2vw);   
    }
  }
  i {
    font-size: 6rem;
    color:$orange;
    animation: fadeinout 4s linear 5 forwards;
    opacity: 0;
  }
}

@keyframes fadeinout {
  50% { opacity: 1; }
}

@media (min-width: 768px) {
  .title h1 {
    font-size: 300px;
  }
}
