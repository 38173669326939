.project-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 0px;
  background-color: rgba(8, 14, 32, 1);
  border-top: 5px solid $orange;
  border-bottom: 5px solid $orange;
  box-shadow: 10px 10px 20px 5px #ffff, -10px -10px 20px 5px #ffff;
  color: #ffff;
  text-align: center;
  h2 {
    margin-bottom: 20px;
  }
}


.project-screen {
  background-image: url('../../images/projects/mac_screen.png');
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 464px;
  height: 375px;
  display: inline-block;
  position: relative;
  .slider-container {
    img {
      width: 100%;
      border-radius: 2px;
    }
  }
  
  .slick-active, .slick-current {
    padding: 15px 16px 14px 16px;
  }
  
  .slick-next {
    right: -25px;
  }
  
  .slick-prev {
    left: -45px;
  }
  
  .slick-prev:before, .slick-next:before {
    font-size: 40px;
    color: $darkblue;
  }
}


.project {
  background-color: #ffff; 
  border: 2px solid $orange;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  color: $darkblue;
  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  p:first-of-type {
    font-style: italic;
    font-size: 1.3rem;
  }
  .project-skills {
    margin-top: 10px;
    margin-bottom: 10px;
    @include flex(row, center, center);
    flex-wrap: wrap;
    i {
      font-size: 22px;
      margin: 0 5px;
    }
  }
}

.pacman img {
  width: 100%;
  margin-bottom: 10px;
}

.button-links {
  @include flex(row, space-evenly, center);
  margin-top: 20px;
  button {
    @include flex(row, space-evenly, center);
    border: 2px solid $orange;
    background-color: #ffff;
    border-radius: 5px;
    padding: 5px 15px;
    margin-left: 5px;
    margin-right: 5px;
    i {
      margin: 0px 5px;
      color: $orange;
    }
    a {
      text-decoration: none;
      color: $orange;
      font-weight: bold;
    }
  }
  button:hover {
    background-color: $orange;
    color: #ffff;
    border: 2px solid $orange;
    i {
      color: #ffff;
    }
    a {
      color: #ffff;
    }
  }
  .fa-link {
    font-size: 20px;
  }
  .github-icon {
    font-size: 23px;
  }
}

// * * Media Query * * 


@media (max-width: 768px) {
  .project-screen {
    .slick-next, .slick-prev {
      top: 120%;
    }
    .slick-next {
      right: 95px;
    }
    .slick-prev {
      left: 70px;
    }
  }
}

@media (max-width: 550px) {
  .project-screen {
    width: 310px;
    height: 250px;
    .slick-active, .slick-current {
      width: 100%;
      padding: 11px 12px 10px 12px;
    }  
    .slick-next {
      right: 75px;
    }
    .slick-prev {
      left: 60px;
    }
    .slick-prev:before, .slick-next:before {
      font-size: 30px;
    }
  }
}

