.interests-wrapper {
  margin-top: 20px;
}

.interests-container {
  @include flex (row, center, flex-start);
  .interest {
    img {
      max-height: 90px;
      margin: 5px;
    }
    h3 {
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
    }
    p {
      text-align: justify; 
      margin: 0px 5px;
    }
  }
}

.interest-text {
  height: 100%;
}